export const API_CONSTANTS = {
  BASEURL: "https://khdbzz64af.execute-api.us-east-1.amazonaws.com/stage/",
  BASEURL_1: "https://5kdjpfaewa.execute-api.us-east-1.amazonaws.com/stage/",
  BASEURL_2: "https://k8awa53o0e.execute-api.us-east-1.amazonaws.com/stage/",
  BASEURL_3: "https://4iadyk0glc.execute-api.us-east-1.amazonaws.com/stage/",
  BASEURL_4: "https://w6fkqawoc7.execute-api.us-east-1.amazonaws.com/stage/",
  LABURL: "https://m4at17nmzl.execute-api.us-east-1.amazonaws.com/stage/",
  SENDOTPBASEURLPATIENT:
    "https://ya99mgvppl.execute-api.us-east-1.amazonaws.com/stage/",
  DOCTORSENDOTPBASEURL:
    "https://xxb4n2vvj2.execute-api.us-east-1.amazonaws.com/stage/",
  CASH_FLOW_API:
    "https://5kdjpfaewa.execute-api.us-east-1.amazonaws.com/stage/admin/cashFlow",
  DASHBOARD_DATA:
    "https://5kdjpfaewa.execute-api.us-east-1.amazonaws.com/stage/admin/getdashboard",
  NURSESENDOTPBASEURL:
    "https://auyhizws3i.execute-api.me-south-1.amazonaws.com/prod/",
  // BASEURL: 'https://doctorapp.eshaafi.com',
  //----login Screen-----//
  LOGIN_API: () => `${API_CONSTANTS.BASEURL}admin/auth`,
  //------ADMIN SIDE API-----//
  ADD_ADMIN: () => `${API_CONSTANTS.BASEURL}admin`,
  ADMIN_LIST: () => `${API_CONSTANTS.BASEURL}admin`,
  ADMIN_DETAIL: (id) => `${API_CONSTANTS.BASEURL}admin/${id}`,
  BLOCK_ADMIN: (id) => `${API_CONSTANTS.BASEURL}admin/disableAdmin/${id}`,
  UPDATE_ADMIN: (id) => `${API_CONSTANTS.BASEURL}admin/updateAdmin/${id}`,
  UNBLOCK_ADMIN: (id) => `${API_CONSTANTS.BASEURL}admin/enableAdmin/${id}`,
  //callLogs Api
  CONSULTATION_CALL_LOGS: (id) =>
    `${API_CONSTANTS.BASEURL_1}admin/appointmentlogs/${id}`,
  /// App feedBack  path//
  App_FeedBack: () => `${API_CONSTANTS.BASEURL_1}admin/users/feedbacks`,
  Appfeedback_Read: (id) =>
    `${API_CONSTANTS.BASEURL_1}admin/users/markread/${id}`,
  CONSULTATION_FOLLOWUP: (docId) =>
    `${API_CONSTANTS.BASEURL_1}admin/doctors/${docId}/appointment/follow-up`,
  CONSULTATION_FOLLOWUP_SLOTS: (docId, appointId) =>
    `${API_CONSTANTS.BASEURL_1}admin/doctors/${docId}/appointment/slots?appointment_id=${appointId}`,
  RESCHEDULE_APPOINTMENT: (appointId) =>
    `${API_CONSTANTS.BASEURL_1}admin/appointment/${appointId}/reschedule`,
  CONSULTATION_RESCHEDULE: () =>
    `${API_CONSTANTS.BASEURL_1}admin/patients/doctor/appointmentSlots`,
  POSTAPPINTMENTSLOTS: () =>
    `${API_CONSTANTS.BASEURL_1}admin/patients/doctor/appointmentSlots`,
  DOCTOR_LIST_SEARCH: () => `${API_CONSTANTS.BASEURL_1}admin/doctors/verified`,
  // transaction module Apis
  ADMIN_TRANSACTION_LIST: () => `${API_CONSTANTS.BASEURL_1}admin/transactions`,
  // add data page apis
  // Services API
  SERVICES_LIST: (type) =>
    `${API_CONSTANTS.BASEURL_3}admin/nurse/services/type/${type}`,
  //------DOCTOR SIDE API-----/---------------------------------
  NURSE_LIST: () => `${API_CONSTANTS.BASEURL_3}admin/nurses`,
  NURSE_SENDOTP: () => `${API_CONSTANTS.NURSESENDOTPBASEURL}auth/sendOtp`,
  NURSE_CREATEOTP: () => `${API_CONSTANTS.NURSESENDOTPBASEURL}auth/verifyOtp`,
  NURSE_PERSONALINFO: (id) =>
    `${API_CONSTANTS.BASEURL_3}admin/nurses/${id}/personalInfo`,
  NURSE_OVERVIEWINFO: (id) => `${API_CONSTANTS.BASEURL_3}admin/nurses/${id}`,
  NURSE_NOTIFICATION: () =>
    `${API_CONSTANTS.BASEURL_3}admin/nurse/notification/`,
  NURSE_APPIONTMENTLIST: (id) =>
    `${API_CONSTANTS.BASEURL_3}admin/nurse/${id}/appointments`,
  NURSE_SEARCHBYNUMBER: (countryCode, countrynumber) =>
    `${API_CONSTANTS.BASEURL_3}/admin/nurseSearch/${countryCode}${countrynumber}`,
  NURSE_BLOCKPROFILE: (id) =>
    `${API_CONSTANTS.BASEURL}admin/updateUserState/${id}/type/3/state/disable`,
  NURSE_UNBLOCKPROFILE: (id) =>
    `${API_CONSTANTS.BASEURL}admin/updateUserState/${id}/type/3/state/enable`,
  NURSETIMESLOT: (id) =>
    `${API_CONSTANTS.BASEURL_3}admin/nurses/${id}/sessions`,
  headers: () => {
    return {
      "Content-Type": "application/json",
      Authorization: JSON.parse(localStorage.getItem("storedData")),
    };
  },
  imgheaders: () => {
    return {
      "Content-Type": "image/jpeg",
      Authorization: JSON.parse(localStorage.getItem("storedData")),
    };
  },
};
